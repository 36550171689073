import React, { useEffect, useState } from "react";
import { Card, CardMedia, CardContent, Typography, Button, Box } from '@mui/material';
import { ReactComponent as Arrow } from "../../assets/icons/arrow-forward.svg";
import { createSignedUrl } from "../../services/amplify/property";
import PropertySkeleton from "../PropertySkeleton";
import getCurrency from "../../utils/getCurrency";

const RoomCard = ({ imageSrc, roomName, price, handleExplore }) => {
  const [imageUrl, setImageUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Function to fetch signed URL
  const fetchSignedUrl = async (key) => {
    try {
      const response = await createSignedUrl(key);
      setImageUrl(response);
      setLoading(false);
    } catch (err) {
      setError('Error fetching the image');
      setLoading(false);
    }
  };

  useEffect(() => {
    if (imageSrc) {
      fetchSignedUrl(imageSrc);
    }
  }, [imageSrc]);

  return (
    <Card
      sx={{
        maxWidth: '100%',
        borderRadius: '16px',
        boxShadow: '0px 0px 0px 0px',
        border: '1px solid #E1E6EA',
      }}
    >
      {/* Room Image */}
      {
        loading
          ? <PropertySkeleton />
          :
          error ?
            <Box
              sx={{
                borderTopLeftRadius: '16px',
                borderTopRightRadius: '16px',
                height: '400px',
                backgroundColor: '#E1E6EA',
              }}
            >
              <Typography variant="body2" color="textSecondary" textAlign="center" mt={2}>
                {"Error fetching the image"}
              </Typography>
            </Box>
            :
            <CardMedia
              component="img"
              height="200"
              image={imageUrl}
              alt={roomName}
              sx={{
                borderTopLeftRadius: '16px',
                borderTopRightRadius: '16px',
                height: '400px',
              }}
            />
      }

      {/* Room Details */}
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <div>
            <Typography variant="h6" component="div" fontWeight="bold">
              {roomName}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {getCurrency(price)}
            </Typography>
          </div>
          <Button
            endIcon={<Arrow />}
            onClick={handleExplore}
            sx={{
              borderRadius: '20px',
              textTransform: 'none',
              color: '#5a5a5a',
              borderColor: '#c1b8a9',
              padding: '6px 16px',
              backgroundColor: '#BFB8AE'
            }}
          >
            Explore Design
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default RoomCard;
