import { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";

// Components
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import PageWrapper from "../components/PageWrapper";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import CatalogDetailModal from "../../../components/CatalogDetailModal";
import PropertySkeleton from "../../../components/PropertySkeleton";
import Pagination from "../../../components/Pagination/Pagination";
import ImageComponent from "../../../components/Image/ImageComponent";
import ShareDesign from "../../../components/Share/ShareDesign";
import RoomImages from "../../../components/PropertySheet/RoomImages";

// Icons
import { IconButton } from "@mui/material";
import { ReactComponent as Search } from "../../../assets/icons/search.svg";
import { ReactComponent as Add } from "../../../assets/icons/add.svg";
import { ReactComponent as Delete } from "../../../assets/icons/delete.svg";
import { ReactComponent as Eye } from "../../../assets/icons/password-visible.svg";
import { ReactComponent as Show } from "../../../assets/icons/open.svg";
import { ReactComponent as Hide } from "../../../assets/icons/hide.svg";
import { ReactComponent as AddCircle } from "../../../assets/icons/add-circle.svg";

// Utils & Services
import { uploadFile } from "../../../services/amplify/property";
import { getUser } from "../../../services/amplify/helpers";
import getCurrency from "../../../utils/getCurrency";

// Redux
import {
  addImage,
  addProduct,
  removeImage,
  removeProduct,
  setPropertySheet,
  setSelectedRoom
} from "../../../store/slice/propertySlice";
import {
  useFetchCatalogProductsQuery,
} from "../../../store/api/propertyApi";

import { useCreatePropertySheetMutation, useFetchPropertySheetVersionsQuery, useUpdatePropertySheetMutation } from "../../../store/api/propertySheetApi";

// Sub-components
const RoomCard = ({ room, selectedRoom, onRoomSelect }) => {
  const active = selectedRoom?.name === room.name;

  return (
    <div
      onClick={() => onRoomSelect(room)}
      className={`bg-background-white rounded-lg px-4 py-5 cursor-pointer flex flex-col gap-1 border-2 
        border-background-dark-gray transition-colors${active ? "border border-secondary" : "hover:border-primary/10"}`}
    >
      <div className="flex justify-between">
        <div className="text-lg font-medium">{room.name}</div>
        <div className="text-primary/50 font-light">{getCurrency(Number(room?.cost || 0))}</div>
      </div>
      <div className="text-primary/50 text-sm">{Number(room?.itemsCount || 0)} item(s) selected</div>
    </div>
  );
};

const CatalogCard = ({ catalog, onViewDetails, onAddProduct }) => {
  const data = typeof catalog?.JsonFeedData === "string" && JSON.parse(catalog?.JsonFeedData);
  const productType = data?.productType?.split(">")?.pop()?.trim();

  return (
    <div className="bg-background-white h-[230px] shadow-lg rounded-lg flex gap-4 p-6">
      <div className="aspect-square w-1/3 rounded-md overflow-hidden">
        <img src={data.image_link} alt={`catalog-${catalog.id}`} className="size-full object-cover object-center" />
      </div>
      <div className="flex flex-col gap-2 flex-1 justify-between">
        <div className="flex flex-col gap-0.5">
          <div className="text-lg font-semibold">
            {data.title?.substring(0, 30)} {data.title?.length > 30 ? "..." : ""}
          </div>
          <div className="text-primary/50 uppercase">
            {productType?.substring(0, 30)} {productType?.length > 30 ? "..." : ""}
          </div>
        </div>
        <div className="text-lg font-semibold">{getCurrency(data.price)}</div>
        <div className="flex items-center gap-1 self-end">
          <IconButton onClick={() => onViewDetails(data)}>
            <Eye className="h-6 w-6" />
          </IconButton>
          <IconButton onClick={() => onAddProduct(catalog, data)}>
            <Add />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

const SelectedProducts = ({ selectedRoom, onRemoveProduct }) => {
  if (!selectedRoom?.items?.length) return null;

  return (
    <div className="flex flex-wrap gap-5">
      {selectedRoom.items.map((item, index) => (
        <div key={index} className="size-16 border border-primary/40 relative group rounded-md">
          <img src={item.image_link} alt="selected-item" className="size-full object-cover object-center rounded-md" />
          <div className="rounded-full size-6 box-center text-sm absolute bg-primary text-background-white border border-background-dark-gray -top-3 -right-3">
            {item.quantity}
          </div>
          <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-40 cursor-pointer transition-opacity" />
          <div className="group-hover:opacity-100 opacity-0 transition-opacity absolute-center bg-black rounded-full">
            <IconButton onClick={() => onRemoveProduct(item.id)}>
              <Delete />
            </IconButton>
          </div>
        </div>
      ))}
    </div>
  );
};

const SheetDetailPage = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { propertySheet, selectedRoom } = useSelector(state => state.property);

  // State
  const [modalState, setModalState] = useState({ isOpen: false, data: null });
  const [searchState, setSearchState] = useState({ query: "", search: "" });
  const [uiState, setUiState] = useState({
    showCatalog: false,
    scrolled: false,
    isFormUpdated: false,
    roomImageUploading: false
  });
  const [page, setPage] = useState(1);
  const [user, setUser] = useState(null);
  const [canvasImage, setCanvasImage] = useState(null);
  const [resultData, setResultData] = useState([]);

  // Queries and Mutations
  const { isLoading, data, isFetching } = useFetchCatalogProductsQuery({
    page,
    perPage: 9,
    search: searchState.search
  });

  const { isLoading: fetchingSheets, data: mySheets } = useFetchPropertySheetVersionsQuery(
    { userId: user?.userId, propertyId: state.id },
    { skip: !user?.userId }
  );

  const [createSheet, { isLoading: creatingSheet }] = useCreatePropertySheetMutation();
  const [updateSheet, { isLoading: updatingSheet }] = useUpdatePropertySheetMutation();

  // Effects
  useEffect(() => {
    const handleScroll = () => setUiState(prev => ({ ...prev, scrolled: window.scrollY > 1 }));
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    getUser().then(setUser);
  }, []);

  useEffect(() => {
    if (data) {
      setResultData(data?.data);
      localStorage.setItem('lastEvaluatedKey', JSON.stringify({ val: data?.last_evaluated_key }));
    }
  }, [data]);

  useEffect(() => {
    setCanvasImage(selectedRoom?.images?.length ? selectedRoom?.images[0]?.src : state?.thumbnail_image);
  }, [selectedRoom, state?.thumbnail_image]);

  const initializePropertySheet = useCallback(() => {
    const roomsMade = state?.included_rooms?.map((room, i) => ({
      id: i,
      name: room,
    }));

    dispatch(setPropertySheet({
      propertyId: state.id || state?.ItemID,
      rooms: roomsMade,
      buyerTotal: 0,
    }));

    dispatch(setSelectedRoom(roomsMade[0]));
  }, [state, dispatch]);

  useEffect(() => {
    initializePropertySheet();
  }, [initializePropertySheet]);

  useEffect(() => {
    if (!fetchingSheets && mySheets?.length) {
      const propertyRooms = state?.included_rooms?.map((room) => ({
        name: room,
        cost: 0,
        itemsCount: 0,
        items: [],
      }));

      const sheetRooms = mySheets[0]?.rooms;
      const allRooms = propertyRooms.map(room => {
        const sheetRoom = sheetRooms.find(sr => sr.name === room.name);
        return sheetRoom || room;
      });

      const totalBuyerCost = allRooms.reduce((acc, room) => acc + Number(room.cost), 0);

      dispatch(setPropertySheet({
        ...mySheets[0],
        rooms: allRooms,
        buyerTotal: totalBuyerCost
      }));

      dispatch(setSelectedRoom(allRooms[0]));
    }
  }, [fetchingSheets, mySheets, state, dispatch]);

  // Handlers
  const handleImageUpload = async (event) => {
    const selectedFiles = Array.from(event.target.files);

    if (selectedFiles.length > 10) {
      toast.error("Max 10 files can be uploaded at one time");
      return;
    }

    if (selectedFiles.length > 0) {
      setUiState(prev => ({ ...prev, roomImageUploading: true }));
      try {
        const result = await uploadFile(
          selectedFiles[0],
          `images/properties/${state?.id || "default"}/${selectedRoom?.name?.replace(/\s+/g, "-")}`
        );
        setUiState(prev => ({ ...prev, isFormUpdated: true }));
        dispatch(addImage({
          name: selectedRoom?.name,
          image: { src: result?.path }
        }));
      } catch (error) {
        toast.error("Image upload failed");
      } finally {
        setUiState(prev => ({ ...prev, roomImageUploading: false }));
      }
    }
  };

  const handleDeleteRoomImage = useCallback((index) => {
    setUiState(prev => ({ ...prev, isFormUpdated: true }));
    dispatch(removeImage({
      name: selectedRoom?.name,
      id: index,
    }));
  }, [dispatch, selectedRoom?.name]);

  const handleSubmit = async () => {
    const dataToSend = {
      userId: user?.userId,
      propertyId: state.id,
      buyerTotal: propertySheet?.buyerTotal || 0,
      rooms: propertySheet?.rooms?.map(room => ({
        name: room.name,
        items: room.items?.map(item => ({
          id: item.id,
          quantity: item.quantity,
          price: item.price,
          image_link: item.image_link,
        })) || [],
        itemsCount: room.itemsCount || 0,
        cost: room.cost || 0,
        images: room.images || [],
      })),
    };

    const mutation = Number(propertySheet?.version) > 0 ? updateSheet : createSheet;
    const successMessage = Number(propertySheet?.version) > 0 ? "Sheet updated successfully" : "Sheet created successfully";
    const errorMessage = Number(propertySheet?.version) > 0 ? "Sheet update failed" : "Sheet creation failed";

    try {
      await mutation(JSON.stringify(dataToSend)).unwrap();
      setUiState(prev => ({ ...prev, isFormUpdated: false }));
      toast.success(successMessage);
    } catch {
      toast.error(errorMessage);
    }
  };

  const handleAddProduct = useCallback((catalog, data) => {
    setUiState(prev => ({ ...prev, isFormUpdated: true }));
    dispatch(addProduct({
      name: selectedRoom?.name,
      product: {
        id: catalog?.id,
        image_link: data.image_link,
        title: data.title,
        price: data.price,
        ItemID: catalog?.ItemID,
        quantity: 1,
      }
    }));
  }, [dispatch, selectedRoom?.name]);

  return (
    <>
      <CatalogDetailModal
        open={modalState.isOpen}
        openSet={(isOpen) => setModalState(prev => ({ ...prev, isOpen }))}
        data={modalState.data}
      />
      <div className="flex flex-col">
        <Sidebar>
          <div className="flex flex-col justify-between h-full">
            <div className="flex flex-col gap-5 w-full">
              <Header text="Room Sheets" includeBackBtn />
              <div className="flex flex-col gap-3 lg:px-3 lg:max-h-[calc(100vh-230px)] overflow-y-auto">
                {propertySheet?.rooms?.length ? (
                  propertySheet.rooms.map((room, index) => (
                    <RoomCard
                      key={index}
                      room={room}
                      selectedRoom={selectedRoom}
                      onRoomSelect={(room) => dispatch(setSelectedRoom(room))}
                    />
                  ))
                ) : (
                  <div>This property has no rooms...</div>
                )}
              </div>
            </div>
            <div className="fixed lg:relative bg-background-white lg:bg-transparent lg:pr-0 px-10 lg:px-3 border-t lg:border-none border-t-primary/10 py-6 lg:py-0 z-10 left-0 md:pl-32 lg:pl-0 bottom-0 w-full">
              <div className="lg:border-t border-t-primary/20 flex justify-between lg:pt-3 text-lg font-medium">
                <div>Buyer Total</div>
                <div>{getCurrency(propertySheet?.buyerTotal)}</div>
              </div>
            </div>
          </div>
        </Sidebar>

        <PageWrapper className="!py-0 !pb-5 !px-5">
          <div className="flex flex-1 flex-col gap-8 size-full relative max-h-screen overflow-y-auto">
            <div className="grid grid-cols-2 gap-5">
              <div className={`col-span-2 flex items-center w-full gap-4 py-3 px-5 sticky top-0 z-10 ${uiState.scrolled ? 'bg-white shadow-sm' : 'bg-white shadow-sm'
                }`}>
                <div className="flex-1 flex gap-4">
                  {selectedRoom && propertySheet?.rooms?.length && (
                    <RoomImages
                      selectedRoom={propertySheet?.rooms?.find(room => room.name === selectedRoom?.name)}
                      propertySheet={propertySheet} canvasImageSet={setCanvasImage}
                      handleDeleteRoomImage={handleDeleteRoomImage}
                      isFormUpdatedSet={(isUpdated) => setUiState(prev => ({ ...prev, isFormUpdated: isUpdated }))} />
                  )}
                  <div className="relative size-16 flex justify-center items-center bg-[#EAEAEA] border-[1px] border-[#BCC2C7] border-dashed rounded-lg">
                    <input type="file" onChange={handleImageUpload} className="w-20 opacity-0 absolute inset-0 z-20 cursor-pointer" multiple disabled={uiState.roomImageUploading} />
                    <AddCircle className={`size-8 text-primary ${uiState.roomImageUploading ? 'cursor-not-allowed' : 'cursor-pointer'}`} />
                  </div>
                </div>
                {!uiState.isFormUpdated ? (<ShareDesign propertyId={state.id} propertySheetId={propertySheet?.id} />) :
                  (<Button loading={creatingSheet || updatingSheet} small onClick={handleSubmit}> Save Changes </Button>)}
              </div>
              <div className={`col-span-2 rounded-xl overflow-hidden ${uiState.showCatalog ? 'max-h-[50vh]' : 'max-h-[70vh]'} relative`}>
                <ImageComponent imageKey={canvasImage} className="size-full object-cover lg:min-h-52" />
                <div className="flex justify-between items-center absolute bottom-5 left-2">
                  <SelectedProducts
                    selectedRoom={propertySheet?.rooms?.find(room => room.name === selectedRoom?.name)}
                    onRemoveProduct={(id) => {
                      setUiState(prev => ({ ...prev, isFormUpdated: true }));
                      dispatch(removeProduct({ name: selectedRoom?.name, id }))
                    }}
                  />
                </div>
              </div>
            </div>

            {selectedRoom ? (
              <div className="w-full h-full flex flex-col gap-12">
                <div className="flex flex-col gap-6 flex-1">
                  <Header text="Product Catalog" />
                  <div className="flex w-full flex-col-reverse md:flex-row items-center justify-between gap-4">
                    <div className="flex w-full items-stretch">
                      <Input
                        value={searchState.query}
                        onChange={(e) => setSearchState(prev => ({ ...prev, query: e.target.value }))}
                        className="bg-background-white !rounded-r-none"
                        containerClassName="w-full"
                        placeholder="Search Products..."
                        leftIcon={<Search />}
                      />
                      <Button
                        disabled={isLoading || isFetching}
                        onClick={() => setSearchState(prev => ({ ...prev, search: prev.query }))}
                        className="!rounded-l-none !w-fit"
                      >
                        Search
                      </Button>
                      <IconButton
                        variant="outlined"
                        style={{ border: "1px solid #BCC2C7", backgroundColor: "transparent" }}
                        disabled={isLoading || isFetching}
                        onClick={() => setUiState(prev => ({ ...prev, showCatalog: !prev.showCatalog }))}
                        className="!min-w-24 !text-black !rounded-lg !ml-5 !text-sm !font-normal !flex !justify-center"
                      >
                        {uiState.showCatalog ? <Hide className="size-5 mr-2" /> : <Show className="size-5 mr-2" />}
                        {uiState.showCatalog ? "Close" : "Open"}
                      </IconButton>
                    </div>
                  </div>
                </div>
                {uiState.showCatalog && (
                  <>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-5">
                      {isLoading ? (
                        Array.from({ length: 9 }).map((_, index) => <PropertySkeleton key={index} skeleton />)
                      ) : resultData.length ? (
                        resultData.map((catalog, index) => (
                          <CatalogCard
                            key={index}
                            catalog={catalog}
                            onViewDetails={(data) => setModalState({ isOpen: true, data })}
                            onAddProduct={handleAddProduct}
                          />
                        ))
                      ) : (
                        <div>No property catalog found...</div>
                      )}
                    </div>
                    {(!isLoading || !isFetching) && (
                      <Pagination
                        className="mx-auto"
                        page={page}
                        pageSet={setPage}
                        count={Math.ceil(data?.total / 9)}
                      />
                    )}
                  </>
                )}
              </div>
            ) : (
              <div className="flex-1 mt-20 box-center">Select Room to continue</div>
            )}
          </div>
        </PageWrapper>
      </div>
    </>
  );
};

export default SheetDetailPage;