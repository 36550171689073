import React, { useCallback, useState } from 'react'

import { loadStripe } from '@stripe/stripe-js';
import { config } from '../../constants';
import Button from '../Button';
import toast from 'react-hot-toast';

const Checkout = ({
    data
}) => {
    const [loading, setLoading] = useState(false);

    const stripePromise = useCallback(() =>
        loadStripe(config.STRIPE.PK), []);

    const handleClick = async () => {
        setLoading(true);
        try {
            const stripe = await stripePromise();
            const { error } = await stripe.redirectToCheckout({
                lineItems: [
                    { price: config.STRIPE.PRICE_ID, quantity: 1 },
                ],
                mode: 'payment',
                // send some extra details to stripe like property id, room name
                successUrl: config.COMMON.BASE_URL + config.PATH_URI.CHECKOUT_CONFIRMATION + '?propertyId=' + data?.propertyId + '&roomId=' + data?.roomId + '&userId=' + data?.userId + '&propertySheetId=' + data?.propertySheetId, 
                cancelUrl: 'https://example.com/cancel',
            });

            if (error) {
                toast.error("An error occurred while processing your payment. Please try again later.");
            }

        } catch (error) {
            toast.error("An error occurred while processing your payment. Please try again later.");
        } finally {
            setLoading(false);
        }
    }

    return (
        <Button
            disabled={loading}
            loading={loading}
            onClick={() => handleClick()}
            variant="contained" className="bg-black text-white rounded-md shadow-lg">
            Quick Buy
        </Button>
    )
}

export default Checkout