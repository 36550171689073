import React from 'react'
import Navbar from '../../components/Navbar'
import Confirmation from '../../components/Checkout/Confirmation'

const CheckoutConfirmationPage = () => {
    return (
        <div className='w-[100vw]'>
            <Navbar login={true} logoAlign='start' landingPage={true} />
            <div className='mt-20' />
            <div className='flex justify-center items-center flex-col w-full'>
                <div className='w-full lg:w-[80%]'>
                    <Confirmation />
                </div>
            </div>
        </div>
    )
}

export default CheckoutConfirmationPage