import React from 'react'
import ImageComponent from '../Image/ImageComponent'
import { Link } from 'react-router-dom'
import { useFetchPropertySheetVersionsQuery } from '../../store/api/propertySheetApi'
import { Skeleton } from '@mui/material'

const Confirmation = () => {
    const params = new URLSearchParams(window.location.search)
    const propertyId = params.get('propertyId')
    const roomId = params.get('roomId')
    const propertySheetId = params.get('propertySheetId')
    const userId = params.get('userId')

    const { isLoading, data } = useFetchPropertySheetVersionsQuery({
        propertyId: propertyId,
        userId: userId,
        roomId: roomId,
        propertySheetId: propertySheetId
    }, {
        skip: !propertyId || !userId || !roomId || !propertySheetId
    })
    
    return (
        <div className='flex justify-center items-center flex-col'>
            {
                isLoading && data ? <Skeleton variant='rectangular' width='100%' height='400px' className='rounded-lg my-20' />
                : <ImageComponent imageKey={
                    data?.rooms[0]?.images[0]?.src
                } alt='placeholder' className='w-[90%] lg:!w-[50vw] lg:h-[400px] !object-cover rounded-lg my-20' />
            }
            <div className='w-full md:w-[50%]'>
                <h3 className='text-2xl text-center my-5'>We will reach out within 2 business days</h3>
                <div className='text-center my-5'>One of our representatives will reach out to work with you on fine tuning your order and making final installation decisions. To contact us, please
                    <Link to={'https://www.reverie3d.com/contact'} target='_blank' className='font-bold cursor-pointer'>
                        {" "} send us a message.
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Confirmation