export default function PageWrapper({ children, className = "" }) {
  return (
    <div
    style={{
      width: "calc(100vw - 500px)",
    }}
      className={`${className} flex relative md:pl-[112px] !ml-[500px] px-4 lg:px-10 py-12 pb-24 lg:pb-12 lg:min-h-screen 3xl:mx-auto 3xl:pl-[500px] 3xl:container 3xl:max-w-screen-3xl`}
    >
      {children}
    </div>
  );
}
